import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, PermissionBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

// Dashboard
const IndexPage = lazy(() => import('src/pages/dashboard/app'));


// Land and Properties pages
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
const ProvincesListPage = lazy(() => import('src/pages/dashboard/location/province/list'));
const ProvinceNewPage = lazy(() => import('src/pages/dashboard/location/province/new'));
const ProvinceEditPage = lazy(() => import('src/pages/dashboard/location/province/edit'));
const DistrictsListPage = lazy(() => import('src/pages/dashboard/location/district/list'));
const DistrictNewPage = lazy(() => import('src/pages/dashboard/location/district/new'));
const DistrictEditPage = lazy(() => import('src/pages/dashboard/location/district/edit'));
const CommunesListPage = lazy(() => import('src/pages/dashboard/location/commune/list'));
const CommuneNewPage = lazy(() => import('src/pages/dashboard/location/commune/new'));
const CommuneEditPage = lazy(() => import('src/pages/dashboard/location/commune/edit'));
const VillagesListPage = lazy(() => import('src/pages/dashboard/location/village/list'));

// ------------------------------------------------------------------------------------------------

// setting
// ------------------------------------------------------------------------------------------------

const WebsiteSettingPage = lazy(() => import('src/pages/dashboard/website/setting/setting'));
const MenuListPage = lazy(() => import('src/pages/dashboard/website/setting/menu'));
// ------------------------------------------------------------------------------------------------

// Website
// ------------------------------------------------------------------------------------------------

const BlocksListPage = lazy(() => import('src/pages/dashboard/website/blocks/list'));
const BlockCreatePage = lazy(() => import('src/pages/dashboard/website/blocks/create'));
const BlockEditPage = lazy(() => import('src/pages/dashboard/website/blocks/edit'));

const CategoriesListPage = lazy(() => import('src/pages/dashboard/website/categories/list'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/website/categories/create'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/website/categories/edit'));

const PostsListPage = lazy(() => import('src/pages/dashboard/website/posts/list'));
const PostCreatePage = lazy(() => import('src/pages/dashboard/website/posts/new'));
const PostEditPage = lazy(() => import('src/pages/dashboard/website/posts/edit'));

const PagesListPage = lazy(() => import('src/pages/dashboard/website/pages/list'));
const PageCreatePage = lazy(() => import('src/pages/dashboard/website/pages/new'));
const PageEditPage = lazy(() => import('src/pages/dashboard/website/pages/edit'));

const AlbumListPage = lazy(() => import('src/pages/dashboard/albums/list'));
const AlbumCreatePage = lazy(() => import('src/pages/dashboard/albums/create'));
const AlbumEditPage = lazy(() => import('src/pages/dashboard/albums/edit'));

const EventListPage = lazy(() => import('src/pages/dashboard/events/list'));
const EventCreatePage = lazy(() => import('src/pages/dashboard/events/create'));
const EventEditPage = lazy(() => import('src/pages/dashboard/events/edit'));

const ScheduleListPage = lazy(() => import('src/pages/dashboard/schedules/list'));
const ScheduleCreatePage = lazy(() => import('src/pages/dashboard/schedules/create'));
const ScheduleEditPage = lazy(() => import('src/pages/dashboard/schedules/edit'));

const SlidersListPage = lazy(() => import('src/pages/dashboard/website/sliders/list'));
const SliderCreatePage = lazy(() => import('src/pages/dashboard/website/sliders/create'));
const SliderEditPage = lazy(() => import('src/pages/dashboard/website/sliders/edit'));

// Peeple
// ------------------------------------------------------------------------------------------------

const PeopleListPage = lazy(() => import('src/pages/dashboard/people/people/list'));
const PeopleCreatePage = lazy(() => import('src/pages/dashboard/people/people/create'));
const PeopleEditPage = lazy(() => import('src/pages/dashboard/people/people/edit'));
const PeopleTypeListPage = lazy(() => import('src/pages/dashboard/people/type/list'));
const PeopleTypeCreatePage = lazy(() => import('src/pages/dashboard/people/type/create'));
const PeopleTypeEditPage = lazy(() => import('src/pages/dashboard/people/type/edit'));
const DepartmentListPage = lazy(() => import('src/pages/dashboard/people/department/list'));
const DepartmentCreatePage = lazy(() => import('src/pages/dashboard/people/department/create'));
const DepartmentEditPage = lazy(() => import('src/pages/dashboard/people/department/edit'));
const PositionListPage = lazy(() => import('src/pages/dashboard/people/position/list'));
const PositionCreatePage = lazy(() => import('src/pages/dashboard/people/position/create'));
const PositionEditPage = lazy(() => import('src/pages/dashboard/people/position/edit'));
const RoleListPage = lazy(() => import('src/pages/dashboard/people/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/people/role/create'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/people/role/edit'));

// Careers
// ------------------------------------------------------------------------------------------------

const CareersListPage = lazy(() => import('src/pages/dashboard/careers/list'));
const CareerCreatePage = lazy(() => import('src/pages/dashboard/careers/create'));
const CareerEditPage = lazy(() => import('src/pages/dashboard/careers/edit'));

const DonorsListPage = lazy(() => import('src/pages/dashboard/donors/list'));
const DonorCreatePage = lazy(() => import('src/pages/dashboard/donors/create'));
const DonorEditPage = lazy(() => import('src/pages/dashboard/donors/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <PermissionBasedGuard permission='view_administrator' >
                <AuthGuard>
                    <DashboardLayout>
                        <Suspense fallback={<LoadingScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </AuthGuard>
            </PermissionBasedGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            // --------------------------------------------------------------------------------------------
            {
                path: 'posts',
                children: [
                    { element: <PostsListPage />, index: true },
                    { path: 'create', element: <PostCreatePage /> },
                    { path: ':id/edit', element: <PostEditPage /> },
                ],
            },
            {
                path: 'pages',
                children: [
                    { element: <PagesListPage />, index: true },
                    { path: 'create', element: <PageCreatePage /> },
                    { path: ':id/edit', element: <PageEditPage /> },
                ],
            },
            // {
            //     path: 'events',
            //     children: [
            //         { element: <EventListPage />, index: true },
            //         { path: 'create', element: <EventCreatePage /> },
            //         { path: ':id/edit', element: <EventEditPage /> },
            //     ],
            // },
            // {
            //     path: 'schedules',
            //     children: [
            //         { element: <ScheduleListPage />, index: true },
            //         { path: 'create', element: <ScheduleCreatePage /> },
            //         { path: ':id/edit', element: <ScheduleEditPage /> },
            //     ],
            // },
            // {
            //     path: 'albums',
            //     children: [
            //         { element: <AlbumListPage />, index: true },
            //         { path: 'create', element: <AlbumCreatePage /> },
            //         { path: ':id/edit', element: <AlbumEditPage /> },
            //     ],
            // },
            {
                path: 'people',
                children: [
                    { element: <PeopleListPage />, index: true },
                    { path: 'create', element: <PeopleCreatePage /> },
                    { path: ':id/edit', element: <PeopleEditPage /> },
                ],
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'donors',
                children: [
                    { element: <DonorsListPage />, index: true },
                    { path: 'create', element: <DonorCreatePage /> },
                    { path: ':id/edit', element: <DonorEditPage /> },

                ],
            },
            // --------------------------------------------------------------------------------------------
            {
                path: 'website',
                children: [
                    { element: <CategoriesListPage />, index: true },
                    { path: 'create', element: <CategoryCreatePage /> },
                    { path: ':id/edit', element: <CategoryEditPage /> },

                    { path: 'blocks', element: <BlocksListPage /> },
                    { path: 'blocks/create', element: <BlockCreatePage /> },
                    { path: 'blocks/:id/edit', element: <BlockEditPage /> },

                    { path: 'sliders', element: <SlidersListPage /> },
                    { path: 'sliders/create', element: <SliderCreatePage /> },
                    { path: 'sliders/:id/edit', element: <SliderEditPage /> },
                ],
            },
            // --------------------------------------------------------------------------------------------

            {
                path: 'locations',
                children: [
                    { element: <ProvincesListPage />, index: true },
                    { path: 'create', element: <ProvinceNewPage /> },
                    { path: ':id/edit', element: <ProvinceEditPage /> },
                    { path: 'districts', element: <DistrictsListPage /> },
                    { path: 'districts/create', element: <DistrictNewPage /> },
                    { path: 'districts/:id/edit', element: <DistrictEditPage /> },
                    { path: 'communes', element: <CommunesListPage /> },
                    { path: 'communes/create', element: <CommuneNewPage /> },
                    { path: 'communes/:id/edit', element: <CommuneEditPage /> },
                    { path: 'villages', element: <VillagesListPage /> },
                ]
            },

            {
                path: 'settings',
                children: [
                    {
                        path: 'general',
                        children: [
                            { element: <WebsiteSettingPage />, index: true },

                            { path: 'menus', element: <MenuListPage /> },
                            { path: 'menus/create', element: <MenuListPage /> },
                            { path: 'menus/:id/edit', element: <MenuListPage /> },

                            // { path: 'career-types', element: <CareerTypesListPage /> },
                            // { path: 'career-types/create', element: <CareerTypeCreatePage /> },
                            // { path: 'career-types/:id/edit', element: <CareerTypeEditPage /> },
                        ],
                    },
                    {
                        path: 'people',
                        children: [
                            { element: <PeopleTypeListPage />, index: true },
                            { path: 'create', element: <PeopleTypeCreatePage /> },
                            { path: ':id/edit', element: <PeopleTypeEditPage /> },
                            { path: 'departments', element: <DepartmentListPage /> },
                            { path: 'departments/create', element: <DepartmentCreatePage /> },
                            { path: 'departments/:id/edit', element: <DepartmentEditPage /> },
                            { path: 'positions', element: <PositionListPage /> },
                            { path: 'positions/create', element: <PositionCreatePage /> },
                            { path: 'positions/:id/edit', element: <PositionEditPage /> },

                            { path: 'roles', element: <RoleListPage /> },
                            { path: 'roles/create', element: <RoleCreatePage /> },
                            { path: 'roles/:id/edit', element: <RoleEditPage /> },
                        ],
                    },

                    {
                        path: 'website',
                        children: [
                            { element: <WebsiteSettingPage />, index: true },
                            { path: 'menu', element: <MenuListPage /> },
                        ]
                    },
                ]
            },
            // --------------------------------------------------------------------------------------------
        ],
    },
];
