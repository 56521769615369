// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 120 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
