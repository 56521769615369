// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { useLocales } from 'src/locales';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';


// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      user_roles: action.payload.user_roles,
      user_permissions: action.payload.user_permissions,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      user_roles: action.payload.user_roles,
      user_permissions: action.payload.user_permissions,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      user_roles: action.payload.user_roles,
      user_permissions: action.payload.user_permissions,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      user_roles: null,
      user_permissions: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentLang } = useLocales();

  const initialize = useCallback(async () => {
    try {

      const accessToken = Cookies.get(STORAGE_KEY);
      // const accessToken = sessionStorage.getItem(STORAGE_KEY);


      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(`api/${currentLang.value}/v1/profile`);



        const { user, user_roles, user_permissions } = response.data;


        dispatch({
          type: 'INITIAL',
          payload: {
            user,
            user_roles,
            user_permissions
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            user_roles: null,
            user_permissions: null
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
          user_roles: null,
          user_permissions: null
        },
      });
    }
  }, [currentLang]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (phone, password) => {
    const data = {
      phone,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { accessToken, user, user_roles, user_permissions, } = response.data;


    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        user_roles,
        user_permissions,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (name, latinName, phone, password) => {
    const data = {
      // email,
      password,
      phone,
      name,
      latinName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';


  const status = state.loading ? 'loading' : checkAuthenticated;


  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      user_roles: state.user_roles,
      user_permissions: state.user_permissions,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, state.user_roles, state.user_permissions, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
