const ROOTS = {
  DASHBOARD: '/dashboard',
  DASHBOARD_CAREERS: '/dashboard/careers',
  DASHBOARD_LOCATION: '/dashboard/locations',
  DASHBOARD_PEOPLE: '/dashboard/people',
  DASHBOARD_SETTINGS: '/dashboard/settings',
  DASHBOARD_WEBSITE: '/dashboard/website',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  albums: '/albums',
  about: '/about-us',
  contact: '/contact-us',
  page403: '/403',
  page404: '/404',
  page500: '/500',

  login: `/login`,
  register: `/register`,


  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    posts: {
      root: `${ROOTS.DASHBOARD}/posts`,
      create: `${ROOTS.DASHBOARD}/posts/create`,
      edit: (uuid) => `${ROOTS.DASHBOARD}/posts/${uuid}/edit`,
    },

    // albums: {
    //   root: `${ROOTS.DASHBOARD}/albums`,
    //   create: `${ROOTS.DASHBOARD}/albums/create`,
    //   edit: (uuid) => `${ROOTS.DASHBOARD}/albums/${uuid}/edit`,
    // },
    // events: {
    //   root: `${ROOTS.DASHBOARD}/events`,
    //   create: `${ROOTS.DASHBOARD}/events/create`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/events/${id}/edit`,
    // },
    donors: {
      root: `${ROOTS.DASHBOARD}/donors`,
      create: `${ROOTS.DASHBOARD}/donors/create`,
      edit: (id) => `${ROOTS.DASHBOARD}/donors/${id}/edit`,
    },

    pages: {
      root: `${ROOTS.DASHBOARD}/pages`,
      create: `${ROOTS.DASHBOARD}/pages/create`,
      edit: (id) => `${ROOTS.DASHBOARD}/pages/${id}/edit`,
    },

    people: {
      root: `${ROOTS.DASHBOARD_PEOPLE}`,
      create: `${ROOTS.DASHBOARD_PEOPLE}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_PEOPLE}/${id}/edit`,
    },

    // Careers

    // careers: {
    //   root: `${ROOTS.DASHBOARD_CAREERS}`,
    //   create: `${ROOTS.DASHBOARD_CAREERS}/create`,
    //   edit: (id) => `${ROOTS.DASHBOARD_CAREERS}/${id}/edit`,
    //   types: {
    //     root: `${ROOTS.DASHBOARD_CAREERS}/types`,
    //     create: `${ROOTS.DASHBOARD_CAREERS}/types/create`,
    //     edit: (id) => `${ROOTS.DASHBOARD_CAREERS}/types/${id}/edit`,
    //   },
    // },
    // WEBSITE
    website: {
      root: `${ROOTS.DASHBOARD_WEBSITE}`,
      create: `${ROOTS.DASHBOARD_WEBSITE}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/${id}/edit`,

      blocks: {
        root: `${ROOTS.DASHBOARD_WEBSITE}/blocks`,
        create: `${ROOTS.DASHBOARD_WEBSITE}/blocks/create`,
        edit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/blocks/${id}/edit`,
      },
      sliders: {
        root: `${ROOTS.DASHBOARD_WEBSITE}/sliders`,
        create: `${ROOTS.DASHBOARD_WEBSITE}/sliders/create`,
        edit: (id) => `${ROOTS.DASHBOARD_WEBSITE}/sliders/${id}/edit`,
      },

    },
    locations: {
      root: `${ROOTS.DASHBOARD_LOCATION}`,
      create: `${ROOTS.DASHBOARD_LOCATION}/create`,
      edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/${id}/edit`,

      districts: {
        root: `${ROOTS.DASHBOARD_LOCATION}/districts`,
        create: `${ROOTS.DASHBOARD_LOCATION}/districts/create`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/districts/${id}/edit`,
      },
      communes: {
        root: `${ROOTS.DASHBOARD_LOCATION}/communes`,
        create: `${ROOTS.DASHBOARD_LOCATION}/communes/create`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/communes/${id}/edit`,
      },
      villages: {
        root: `${ROOTS.DASHBOARD_LOCATION}/villages`,
        create: `${ROOTS.DASHBOARD_LOCATION}/villages/create`,
        edit: (id) => `${ROOTS.DASHBOARD_LOCATION}/villages/${id}/edit`,
      },
    },

    // Settings
    settings: {
      general: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/general`,
        menus: {
          root: `${ROOTS.DASHBOARD_SETTINGS}/general/menus`,
          create: `${ROOTS.DASHBOARD_SETTINGS}/general/menus/create`,
          edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/general/menus/${id}/edit`,
        },
        // careerTypes: {
        //   root: `${ROOTS.DASHBOARD_SETTINGS}/general/career-types`,
        //   create: `${ROOTS.DASHBOARD_SETTINGS}/general/career-types/create`,
        //   edit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/general/career-types/${id}/edit`,
        // },
      },
      website: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/website`,
        menu: `${ROOTS.DASHBOARD_SETTINGS}/website/menu`,
      },
      people: {
        root: `${ROOTS.DASHBOARD_SETTINGS}/people`,
        typeCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/create`,
        typeEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/${id}/edit`,
        departments: `${ROOTS.DASHBOARD_SETTINGS}/people/departments`,
        departmentCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/departments/create`,
        departmentEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/departments/${id}/edit`,
        positions: `${ROOTS.DASHBOARD_SETTINGS}/people/positions`,
        positionCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/positions/create`,
        positionEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/positions/${id}/edit`,
        titles: `${ROOTS.DASHBOARD_SETTINGS}/people/titles`,
        titleCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/titles/create`,
        titleEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/titles/${id}/edit`,
        roles: `${ROOTS.DASHBOARD_SETTINGS}/people/roles`,
        roleCreate: `${ROOTS.DASHBOARD_SETTINGS}/people/roles/create`,
        roleEdit: (id) => `${ROOTS.DASHBOARD_SETTINGS}/people/roles/${id}/edit`,
      },
    },


  },
};
