import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [

      {
        title: t('programs'),
        path: paths.statements,
        children: [
          {
            title: t('គម្រោងផែនទីសហគមន៍ កែលម្អលំនៅឋាន និងភាពធន់នៅក្នុងទីក្រុង'),
            path: paths.statements,
          },
          {
            title: t('report'),
            path: paths.statements,
          },
          {
            title: t('report'),
            path: paths.statements,
          },
          {
            title: t('report'),
            path: paths.statements,
          },
          {
            title: t('report'),
            path: paths.statements,
          },
        ]
      },
      {
        title: t('publication'),
        path: paths.statements,
        children: [
          {
            title: t('report'),
            path: paths.statements,
          },
          {
            title: t('feature-report'),
            path: paths.statements,
          },
          {
            title: t('story-of-change'),
            path: paths.statements,
          },
          {
            title: t('story-of-change'),
            path: paths.statements,
          },
          {
            title: t('statement'),
            path: paths.statements,
          },
          {
            title: t('video'),
            path: paths.statements,
          },
          {
            title: t('gallery'),
            path: paths.statements,
          }

        ]
      },
      {
        title: t('videos'),
        path: '#',
      },
      {
        title: 'donation',
        path: '#',
      },
      {
        title: t('contactUs'),
        // icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: '/contact-and-agent',
      },
    ],
    [t]
  );
  return data;
}
