import PropTypes from 'prop-types';
import { useRouter } from 'src/routes/hook';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect } from 'react';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function PermissionBasedGuard({ permission, children, sx }) {
  const router = useRouter();

  // Logic here to get current user permission
  // const { user_permissions } = useMockedUser();
  const { user_permissions } = useAuthContext();

  useEffect(() => {
    if (user_permissions !== null) {
      if (user_permissions?.length === 0 || !user_permissions.includes(permission)) {
        router.replace(paths.page404);
      }
    }
  });
  return <> {children} </>;
}

PermissionBasedGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string,
  sx: PropTypes.object,
};
