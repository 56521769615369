import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/website/home'));

export const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const Maps = lazy(() => import('src/pages/map'));
const AlbumPage = lazy(() => import('src/pages/website/album/album'));
const AlbumShowPage = lazy(() => import('src/pages/website/album/album-show'));
const PagePosts = lazy(() => import('src/pages/website/post/page-posts'));
const PagePostDetail = lazy(() => import('src/pages/website/post/page-post-detail'));
const PageWithSlug = lazy(() => import('src/pages/website/post/page-with-slug'));

// BLOG

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        path: '/albums',
        children: [
          { element: <AlbumPage />, index: true },
          { path: ':uuid', element: <AlbumShowPage /> },
        ]

      },
      { path: '/:category', element: <PagePosts /> },
      { path: '/:category/:slug?', element: <PagePostDetail /> },
      { path: '/:slug', element: <PageWithSlug /> },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [


    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },

    ],
  },
];
